<template>
	<div>
		<h4>Constancia electrónica de riesgos</h4>
		<p class="mt-5">
			Es importante que conozcas algunos riesgos que existen al invertir con nosotros.<br>
			Completa la constancia electrónica que se presenta a continuación para conocer sobre estos riesgos,
			si no estás de acuerdo no podremos continuar con el proceso de verificación de tu cuenta y no podrás invertir.
		</p>
		<h4 class="mt-6">Advertencias.</h4>
		<div class="vx-row mt-4">
			<div :class="colClass" v-for="warning in warningsData" :key="warning.index">
				<div class="flex">
					<div :class="colClassFull">
						<label class="bold mt-5">{{ warning.title }}</label><br>
						<small>{{ warning.subtitle }}</small>
					</div>
				</div>
			</div>
		</div>
		
		<h4 class="mt-5">Riesgos.</h4>
		<div class="main-form mt-5">
			<div class="vx-row mt-4">
				<div :class="colClassFull">
					<div class="mb-base" v-for="risk in risksData" :key="risk.index">
						<vs-row vs-w="12" vs-justify="center" vs-align="center" vs-type="flex">
							<vs-col vs-justify="center" vs-align="center" vs-lg="7">
								<div class="flex flex-wrap justify-start">
									<label class="bold">{{ risk.title }}</label>
								</div>
								<small>{{ risk.subtitle }}</small>
							</vs-col>
							<vs-col vs-justify="center" vs-align="center" vs-lg="5">
								<div class="flex flex-wrap justify-start ml-2">
									<template v-if="risk.value == 0">
										<vs-radio disabled :vs-name="risk.title + '_1'" color="dark" class="w-full mb-2"
											v-model="riskRadioAnswers[risk.value]" :vs-value="1">Sí</vs-radio>
										<vs-radio :vs-name="risk.title + '_0'" color="dark" class="w-full"
											v-model="riskRadioAnswers[risk.value]" :vs-value="0">No, RedGirasol no brinda asesoría
											financiera</vs-radio>
									</template>
									<template v-else>
										<vs-radio :vs-name="risk.title + '_1'" color="dark" class="w-full mb-2"
											v-model="riskRadioAnswers[risk.value]" :vs-value="1">Sí, comprendo</vs-radio>
										<vs-radio disabled :vs-name="risk.title + '_0'" color="dark" class="w-full"
											v-model="riskRadioAnswers[risk.value]" :vs-value="0">No</vs-radio>
									</template>
								</div>
							</vs-col>
						</vs-row>
					</div>
				</div>
			</div>
		</div>

		<h4 class="mt-5">Acerca de la inversión.</h4>
		<div class="main-form mt-5">
			<div class="vx-row mt-4">
				<div :class="colClassFull">
					<div class="mb-base" v-for="investment in investmentsData" :key="investment.index">
						<vs-row vs-w="12" vs-justify="center" vs-align="center" vs-type="flex">
							<vs-col vs-justify="center" vs-align="center" vs-lg="7">
								<div class="flex flex-wrap justify-start">
									<label class="bold">{{ investment.title }}</label>
								</div>
								<small>{{ investment.subtitle }}</small>
							</vs-col>
							<vs-col vs-justify="center" vs-align="center" vs-lg="5">
								<div class="flex flex-wrap justify-start ml-2">
									<vs-radio :vs-name="investment.title + '_1'" color="dark" class="w-full mb-2"
										v-model="investmentsRadioAnswers[investment.value]" :vs-value="1">Sí</vs-radio>
									<vs-radio disabled :vs-name="investment.title + '_0'" color="dark" class="w-full"
										v-model="investmentsRadioAnswers[investment.value]" :vs-value="0">No</vs-radio>
								</div>
							</vs-col>
						</vs-row>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	name: "LatestInvestorRisksContract",
	data() {
		return {
			colClassFull: "vx-col w-full",
			colClass: "vx-col md:w-1/2 sm:w-full w-full mb-5",
			warningQuestion: 1,
			warningsData: [
				{ title: 'Retiro de fondos', subtitle: 'Una vez que inviertas en algún proyecto, no podrás retirar los fondos a menos que se cancele o no logre reunir los fondos requeridos.' },
				{ title: 'Retiro anticipado', subtitle: 'No podrás retirar anticipadamente tus inversiones una vez que se complete el fondeo del proyecto.' },
				{ title: 'No pago del solicitante', subtitle: 'En caso de que el solicitante no pague su crédito, se presentará una demanda para recuperar el monto del crédito. Existe la posibilidad de perder parcial o totalmente la inversión.' },
				{ title: 'Información del solicitante', subtitle: 'Es posible invertir en proyectos que no tengan historial de operación. Existe la posibilidad de perder total o parcialmente la inversión.' },
				{ title: 'Información inicial', subtitle: 'Es posible que la información financiera brindada por los solicitantes no sea auditada por un auditor externo. Existe la posibilidad de perder parcial o totalmente la inversión.' },
				{ title: 'Analiza la información', subtitle: 'La información a la cual tendrás acceso sobre los créditos podrá ser limitada en comparación a lo observado en el mercado de valores.' },
				{ title: 'Rendimientos', subtitle: 'Los rendimientos sobre las inversiones que realices no son garantizados por nosotros ni podemos asegurar que vayan a ser exitosas.' },
				{ title: 'Aceptación de riesgos', subtitle: 'Antes de poder invertir, es necesario que comprendas y aceptes los riesgos, formas y términos generales de la plataforma en el cuestionario a continuación.' },
				{ title: 'Garantía de recursos', subtitle: 'Ni el Gobierno Federal ni las entidades de la administración pública paraestatal podrán responsabilizarse  o garantizar los recursos de los Clientes que sean utilizados en las Operaciones que celebren con las ITF  o frente a otros, así como tampoco asumir alguna responsabilidad por las obligaciones contraídas por las  ITF o por algún Cliente frente a otro, en virtud de las Operaciones que celebren.' },
			],
			risksData: [
				{ title: 'Asesorías', subtitle: '¿Consideras que RedGirasol deberá brindarte asesoría financiera personalizada para determinar si las inversiones son adecuadas?.', value: '0' },
				{ title: 'Generación de rendimientos', subtitle: '¿Comprendes que los montos que no estén invertidos no generarán rendimientos?', value: '1' },
				{ title: 'Disposición de fondos', subtitle: '¿Comprendes que los montos que se encuentren invertidos no podrán ser retirados anticipadamente?', value: '2' },
				{ title: 'Créditos', subtitle: '¿Comprendes que la información sobre los créditos puede ser limitada?', value: '3' },
				{ title: 'Pérdida de dinero', subtitle: '¿Comprendes que las inversiones en RedGirasol no están garantizadas, por lo que es posible que pierdas parte o todo tu dinero?', value: '4' },
				{ title: 'Aprobaciones', subtitle: '¿Comprendes que los créditos publicados no han sido aprobadas por la CNBV u otra autoridad?', value: '5' }
			],
			riskRadioAnswers: [
				0, 1, 1, 1, 1, 1, 1
			],
			investmentsData: [
				{ title: 'Comprendo los riesgos', subtitle: 'He leído esta información y comprendo los riesgos de invertir.', value: '0' },
				{ title: 'Revisión de la información', subtitle: 'Comprendo que deberé leer y entender la información de los solicitantes de crédito.', value: '1' },
			],
			investmentsRadioAnswers: [
				1, 1
			]
		};
	},
	methods: {
	},
};
</script>