<template>
    <section>
        <div class="container-fluid">
            <div class="col-md-12">
                <div class="alt-2"></div>
                <div class="vx-row">
                    <div class="vx-col w-full">
                        <div class="avivon-heading mb-30px">
                            <h1 class="big_title text-center">
                                Formato para solicitar la Domiciliación.
                                <br><br>
                            </h1>
                        </div>
                        <!-- INTRODUCCIÓN -->
                        <p class="text-justify">
                            <strong>Banco del Bajío, S.A. Institución de Banca Múltiple</strong><br><br>
                            Solicito y autorizo que con base en la información que se indica en esta comunicación se realicen cargos periódicos en mi
                            cuenta conforme a lo siguiente:
                        </p>

                        <ol style="list-style-type: decimal;margin-top: 1rem; margin-left: 2rem;">
                            <!-- 1. Usos del sitio -->
                            <li>
                                Nombre del proveedor del bien, servicio o crédito o préstamo, según corresponda, que pretende pagarse por 
                                medio de la domiciliación: <u class="bold">RED GIRASOL, S.A.P.I. de C.V., INSTITUCIÓN DE FINANCIAMIENTO COLECTIVO</u>.
                            </li>
                            <!-- 1. Usos del sitio -->
                            <li>
                                Bien, servicio o crédito, a pagar: <u class="bold">SERVICIO</u>. En su caso, el número de identificación generado por el proveedor (dato no obligatorio): ____
                            </li>
                            <!-- 1. Usos del sitio -->
                            <li>
                                Tratándose de los pagos del crédito o préstamo objeto de esta solicitud, indicar a continuación si este es
                                designado como un Crédito Asociado a la Nómina respecto del cual, de conformidad con las disposiciones
                                emitidas por Banco de México en la Circular 3/2012 o aquellas otras emitidas en el futuro, el banco que lleva la
                                cuenta aquí referida debe realizar los cargos solicitados a esa misma cuenta: Sí _________ No _____X_____.
                            </li>
                            <!-- 1. Usos del sitio -->
                            <li>
                                Periodicidad del pago (Facturación) (Ejemplo: semanal, quincenal, mensual, bimestral, semestral, anual, etc.):
                                <u class="bold">MENSUAL</u> o, en su caso, el día específico en el que se solicita realizar el pago: _______.
                            </li>
                            <li>
                                Nombre del banco que lleva la cuenta de depósito a la vista o de ahorro en la que se realizará el cargo: <u class="bold">BBVA BANCOMER</u>.
                            </li>
                            <li>
                                Cualquiera de los Datos de identificación de la cuenta, siguientes:
                                <ul style="list-style: disc;padding-left: 2rem;">
                                    <li>
                                        Número de tarjeta de débito (16 dígitos): _________________
                                    </li>
                                    <li>
                                        Clave Bancaria Estandarizada (“CLABE”) de la Cuenta (18 dígitos): __________________
                                    </li>
                                    <li>
                                        Número del teléfono móvil asociado a la cuenta: _________________
                                    </li>
                                </ul>
                            </li>
                            <li>
                                Monto máximo fijo del cargo autorizado por periodo de facturación: $_________
                                En lugar del monto máximo fijo, si el crédito indicado en esta solicitud es de un crédito revolvente asociado a una
                                tarjeta de crédito que no sea designado en esta misma solicitud como un Crédito Asociado a la Nómina, el titular
                                de la cuenta podrá optar por autorizar alguna de las opciones de cargo siguientes:<br>
                                El importe del pago mínimo del periodo: ( ),<br>
                                El saldo total para no generar intereses en el periodo: ( ), o<br>
                                Un monto fijo: ( ), (Incluir monto) $ _________
                            </li>
                            <li>
                                Como excepción a lo anterior, si el crédito indicado en esta solicitud es un crédito revolvente designado como
                                Crédito Asociado a la Nómina, indicar a continuación si el cargo mensual deberá hacerse por el límite máximo del
                                10% del promedio de los abonos en la cuenta indicada en esta solicitud por las cantidad correspondientes a las
                                Versión 2.5
                                No. de Contrato: 3614 | Página 21
                                prestaciones laborales del cuentahabiente, calculado conforme a las disposiciones emitidas por Banco de México
                                o, en lugar de dicho límite, un porcentaje inferior:
                                Límite máximo del 10%: $ ________________ .
                            </li>
                            <li>
                                Esta autorización es por plazo indeterminado (X) o vence el: _________________.
                            </li>
                        </ol>

                        <p>
                            Estoy enterado de que en cualquier momento podré solicitar la cancelación de la presente domiciliación sin costo a mi cargo.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
export default {
  name: "LatestInvestorDirectBillingContract",
  data() {
    return {
    };
  },
  methods: {
  },
};
</script>
<style scoped>
    ol {
        list-style-type: none;
        counter-reset: item;
        margin: 0;
        padding: 0;
    }

    ol > li {
        display: table;
        counter-increment: item;
        margin-bottom: 0.6em;
    } 

    ol > li:before {
        content: counters(item, ".") ". ";
        display: table-cell;
        padding-right: 0.6em;    
    }

    li ol > li {
        margin: 0;
    }

    li ol > li:before {
        content: counters(item, ".") " ";
    }

    .custom-table th, td {
        border: solid 1px #ddd;
    }
</style>